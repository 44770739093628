










import { Vue, Component} from 'vue-property-decorator';
import Header from "@/layouts/public/Header/Header.vue";
import Footer from "@/layouts/public/Footer/Footer.vue";


@Component({
  name: "public-layout",
  components: {
    Header,
    Footer
  }
})
export default class PublicLayout extends Vue {

}
