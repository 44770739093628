








































































































































































































































































































































































































































































import {Component, Inject, Vue} from "vue-property-decorator";
import {IGooglePlace} from "@/interfaces/IGooglePlace";
import {Loader, LoaderOptions} from "google-maps";

import Tip from "@/components/Tip/Tip.vue";
import {PlaceService} from "@/services/PlaceService";
import {ReviewsService} from "@/services/ReviewsService";
import {UserService} from "@/services/UserService";
import {IUser} from "@/interfaces/IUser";
import {FavoritesService} from "@/services/FavoritesService";
import Login from "@/components/Login/Login.vue";
import Signup from "@/components/Signup/Signup.vue";
import Modal from "@/components/Modal/Modal.vue";
import ImageModal from "@/components/ImageModal/ImageModal.vue";
import ReviewImageModal from  "@/components/ImageModal/ReviewImageModal.vue";

@Component({
  components: {
    Login,
    Signup,
    Tip,
    Modal,
    ImageModal,
    ReviewImageModal
  },
  metaInfo:{
    title: "Britevue - Blockchain verified reviews where reviewers get paid",
    meta: [
      { name:'title',content:'Britevue - Blockchain verified reviews where reviewers get paid'},
      { name: 'description', content:  'Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!'},
      { itemprop: 'name', content: "Britevue"},
      { itemprop: 'description', content: "Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!"},
      { itemprop: 'image', content:  require('@/assets/images/logo_black.svg')},
      { property: 'og:type', content: "website"},
      { property: 'og:url', content: "https://britevue.com/"},
      { property: 'og:title', content: "Britevue - Blockchain verified reviews where reviewers get paid"},
      { property: 'og:description', content: "Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!"},
      { property: 'og:image', content:  require('@/assets/images/logo_black.svg')},
      { property: 'og:site_name', content: 'britevue'},
      { name: 'twitter:card', content: "summary_large_image"},
      { name: 'twitter:url', content: "https://britevue.com/"},
      { name: 'twitter:title', content: "Britevue - Blockchain verified reviews where reviewers get paid"},
      { name: 'twitter:description', content: "Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!"},
      { name: 'twitter:image', content: require('@/assets/images/logo_black.svg') },
    ]
  }
})
export default class Place extends Vue {
  place_id: string;
  place: IGooglePlace | null;

  @Inject('favoritesService')
  favorites_ser!: FavoritesService;

  @Inject('placeService')
  place_ser!: PlaceService;

  @Inject('reviewsService')
  reviews_ser!: ReviewsService;
  reviews = [];
  showReviews: number;
  @Inject('userService')
  user_service!: UserService;
  profile: IUser | null = null;
  files: File[] = [];
  step: number;
  uploadProgress: number;
  showUploadProgressBar: boolean;
  options: any;
  flash: any;
  tipStatus: boolean;
  loadingReviews: boolean;
  loadingPlace: boolean;
  isFavorite: boolean;
  showHandcashModal: boolean;
  showGoogleImageModal: boolean;
  showBritevueImageModal: boolean;
  mainImageModel: string;
  mainImageIndex: number;
  reviewPhotos: [];
  showReviewImageModal: boolean;
  mainReviewImageModel: string;


  constructor() {
    super();
    this.place_id = '';
    this.showReviews = 6;
    if (typeof this.$route.params.id != 'undefined') {
      this.place_id = this.$route.params.id;
    } else {
      this.$router.push({name: "dashboard"});
    }
    this.place = null;
    this.step = 0;
    this.uploadProgress = 0;
    this.showUploadProgressBar = false;
    this.loadingReviews = true;
    this.loadingPlace = true
    this.options = {
      text: {
        color: '#FFFFFF',
        shadowEnable: true,
        shadowColor: '#000000',
        fontSize: 14,
        fontFamily: 'Helvetica',
        dynamicPosition: false,
        hideText: false
      },
      progress: {
        color: '#cc7a00',
        backgroundColor: '#333333'
      },
      layout: {
        height: 35,
        width: 140,
        verticalTextAlign: 61,
        horizontalTextAlign: 43,
        zeroOffset: 0,
        strokeWidth: 30,
        progressPadding: 0,
        type: 'line'
      }
    }

    this.user_service.updateUserLocalStorage("")
    const user = this.user_service.getCurrentUser();
    if (user) {
      this.profile = user;
      //check user token is expired
      const checkUser = this.user_service.getUser(this.profile.id);
    }
    this.tipStatus=false;
    this.isFavorite=false;
    this.showHandcashModal=false;
    this.showGoogleImageModal=false;
    this.showBritevueImageModal=false;
    this.mainImageModel= '';
    this.mainImageIndex=0;
    this.reviewPhotos=[];
    this.showReviewImageModal=false;
    this.mainReviewImageModel='';
  }


  async mounted() {
    if (this.$route.query.token != null && this.$route.query.username != null) {
      const tokenObj = {
        username: this.$route.query.username,
        token: this.$route.query.token,
        expires_at: this.$route.query.expires_at
      }
      localStorage.setItem('token', JSON.stringify(tokenObj))
      await this.user_service.updateUserLocalStorage(this.$route.query.username as string)
    } else {
      await this.user_service.updateUserLocalStorage("");
    }
    try {
      this.loadingPlace = true
      await this.loadPlaceDetail();
      await this.loadMap();
      this.loadingPlace = false
      await this.loadReviews();
      await this.checkPlaceIsFavorite();
    } catch(e) {
      this.openLogin()
    }
  }

  async deleteReview(review_id: string) {
    if (this.checkUserWallet()) {
      if(confirm('Are you sure you want to delete this review')){
        const deleteReview = await this.reviews_ser.deleteReview(review_id);
        if (deleteReview.status == 200) {
          this.reviews = []
          this.loadReviews()
        } else {
          this.flash("Had a problem deleting review", 'error', {timeout: 10000});
        }
      }
    }

  }

  async editReview(place_id: string, place_name: string, review_id: string) {
    if (this.checkUserWallet()) {
      this.getRoutePath('new_review', {'place_id': place_id, 'place_name': place_name, 'review_id': review_id});
    }
  }

  async loadPlaceDetail() {
    //check first if a place is a brite-vue place , if not fetch fetch from google
    const brite_place = await this.place_ser.getBriteLocation(this.place_id);
    if (brite_place.status == 200) {
      this.place = brite_place.data;
    } else {

      const google_place = await this.place_ser.getGoogleLocation(this.place_id);
      if (google_place.status==200) {
        this.place = google_place.data;
      }
      if(brite_place.status==500 && google_place.status==500){
        this.flash("Place Not Found", 'error', {timeout: 10000});
      }
    }
  }

  async loadMap() {
    const options: LoaderOptions = {/* todo */};
    const loader = new Loader('AIzaSyDMqrI8hMqiyIPdQUkRUerdohx28Fuv4wE', options);
    const google = await loader.load();
    /* eslint-disable @typescript-eslint/no-explicit-any*/
    const con: any = this.$refs.map;
    let lat = -34.397;
    let lng = 150.644;
    if (this.place != null && this.place.geometry != null && this.place.geometry.location != null) {
      lat = this.place.geometry.location.lat
      lng = this.place.geometry.location.lng
    }
    //load map
    new google.maps.Map(con, {
      center: {lat: lat, lng: lng},
      zoom: 15,
    });
  }

  tip(amount: string) {
    console.log('tipping:', amount);
  }

  goToPhotos() {
    this.getRoutePath('place_photos', {'place': this.place});
  }

  async loadReviews() {
    this.loadingReviews = true
    let reviews = await this.reviews_ser.getPlaceReviews(this.place_id);
    if (reviews.status==200) {
      reviews=reviews.data;
      let i;
      for(i=0;i<reviews.length;i++){
        const userInfo=await this.user_service.getUser(reviews[i].user);
        if (userInfo.status==200) {
          reviews[i].userInfo=userInfo.data;
        }
      }
      this.reviews = reviews;
    }
    this.loadingReviews = false
  }

  closingTime() {
    const dt = new Date();
    const dayOfTheWeek = dt.getDay();
    if (this.place == null || this.place.opening_hours == null || this.place.opening_hours.periods == null) {
      return ''
    }
    let hours;
    let minutes;
    let shift;
    if(this.place.opening_hours.open_now==true){
      if(this.place.opening_hours.periods.length==7){
        if(typeof this.place.opening_hours.periods[dayOfTheWeek].close.time!='undefined'){
          hours=this.place.opening_hours.periods[dayOfTheWeek].close.time.substring(0,2);
          minutes=this.place.opening_hours.periods[dayOfTheWeek].close.time.substring(2,4);
          shift='AM';
          if(hours > 12){
            hours-=12;
            shift='PM';
          }
          return hours+':'+minutes+' '+shift;
        }
      }else if(this.place.opening_hours.periods.count==1){
        if(typeof this.place.opening_hours.periods[0].close.time!='undefined'){
          hours=this.place.opening_hours.periods[0].close.time.substring(0,2);
          minutes=this.place.opening_hours.periods[0].close.time.substring(2,4);
          shift='AM';
          if(hours > 12){
            hours-=12;
            shift='PM';
          }
          return hours+':'+minutes+' '+shift;
        }
      }
    }

    return '';
  }

  imageUrlAlt(event: any) {
    event.target.src = require('@/assets/images/no-photo-avatar.png');
  }

  getRoutePath(name: string, parameters: any) {

    return this.$router.push({name: name, params: parameters});
  }

  sortReviews() {

    const sortReview = document.getElementById('sort-reviews') as HTMLInputElement;

    if (sortReview.value == 'name') {
      this.reviews.sort((n1: any, n2: any) => {
        if (n1.user > n2.user) {
          return 1;
        }

        if (n1.user < n2.user) {
          return -1;
        }

        return 0;
      });
    }
    if (sortReview.value == 'date') {
      this.reviews.sort((n1: any, n2: any) => {
        if (n1.timestamp > n2.timestamp) {
          return 1;
        }

        if (n1.timestamp < n2.timestamp) {
          return -1;
        }

        return 0;
      });
    }
  }

  showMoreReviews() {
    this.showReviews += 6;
  }

  async upvote(review_id: string, e: any) {
    e.preventDefault();
    if(this.checkUserWallet()){
      const spinner=document.getElementById('upvote-spinner-' + review_id) as HTMLElement;
      const upvoteElement=document.getElementById('upvote-' + review_id) as HTMLElement;
      const upvoteIcon=document.getElementById('upvote-icon-' + review_id) as HTMLElement;
      spinner.classList.remove('d-none');
      upvoteElement.classList.add('d-none');
      upvoteIcon.classList.add('d-none');
      const res = await this.reviews_ser.upvoteReview(this.profile!.id, review_id);
      spinner.classList.add('d-none');
      upvoteElement.classList.remove('d-none');
      upvoteIcon.classList.remove('d-none');
      if (res.status == 200) {
        const upvoteResult = res.data
        if (typeof upvoteResult.txid != 'undefined') {
          const vote = upvoteElement!.textContent;
          upvoteElement!.innerHTML = (parseInt(vote!) + 1).toString();
          this.flash('Successfully upvoted review', 'success', {timeout: 5000})
        }
      } else if(res.status == 555) {
        this.showHandcashModal = true
      } else {
        const msg = res.data.message
        if (typeof msg != 'undefined') {
          this.flash(msg, 'error', {timeout: 5000})
        }
      }
    }
  }

  async purchaseReview(review_id: string) {
    const spinner=document.getElementById('purchase-spinner-' + review_id) as HTMLElement;
    const button=document.getElementById('purchase-button-' + review_id) as HTMLElement;
    if(this.checkUserWallet()){
      spinner.classList.remove('d-none');
      button.classList.add('d-none')
      const res = await this.reviews_ser.buyReview(review_id)
      if (res.status == 200) {
        this.flash('Successfully purchased review in tx '+res.data.paymentTx, 'success', {timeout: 5000});
      } else if (res.status == 555) {
        this.showHandcashModal = true
      } else {
        this.flash('Failed to purchase review', 'error', {timeout: 5000})
      }
      spinner.classList.add('d-none')
      await this.loadReviews()
    }
  }

  async downvote(review_id: string, e: any) {
    e.preventDefault();
    if(this.checkUserWallet()){
      const spinner=document.getElementById('downvote-spinner-' + review_id) as HTMLElement;
      const downvoteElement=document.getElementById('downvote-' + review_id) as HTMLElement;
      const downvoteIcon=document.getElementById('downvote-icon-' + review_id) as HTMLElement;
      spinner.classList.remove('d-none');
      downvoteElement.classList.add('d-none');
      downvoteIcon.classList.add('d-none');
      const res = await this.reviews_ser.downReview(this.profile!.id, review_id);
      spinner.classList.add('d-none');
      downvoteElement.classList.remove('d-none');
      downvoteIcon.classList.remove('d-none');
      if (res.status == 200) {
        const downvoteResult = res.data
        if (typeof downvoteResult.txid != 'undefined') {
          const vote = downvoteElement!.textContent;
          downvoteElement!.innerHTML = (parseInt(vote!) + 1).toString();
          this.flash('Successfully downvoted review', 'success', {timeout: 5000})
        }
      } else {
        const msg = res.data.message
        if (typeof msg != 'undefined') {
          this.flash(msg, 'error', {timeout: 5000})
        }
      }
    }
  }

  handleFileDrop(e: any) {
    const droppedFiles = e.dataTransfer.files;
    if (!droppedFiles) return;
    // this tip, convert FileList to array
    ([...droppedFiles]).forEach((f: any) => {
      this.files.push(f);
    });
    this.step = 2;
  }

  handleFileInput(e: any) {
    let files = e.target.files;
    files = e.target.files
    if (!files) return;
    // this tip, convert FileList to array
    ([...files]).forEach((f: File) => {
      this.files.push(f);
    });
    this.step = 2;
  }

  removeFile(fileKey: any) {
    this.files.splice(fileKey, 1)
  }

  async uploadImages() {
    this.step = 0;
    this.showUploadProgressBar = true;
    let i = 0;
    let status = false;
    const progress = 100 / (this.files.length);
    for (i = 0; i < this.files.length; i++) {
      //upload images
      const placeImage = await this.place_ser.uploadPlaceImage(this.files[i], this.place_id);
      if (placeImage.status != 200) {
        this.flash(placeImage['data']['message'], 'error', {timeout: 5000});
      }
      if (placeImage.status == 200) {
        status = true;
      }
      this.uploadProgress += parseInt(progress.toFixed(2));
    }
    this.showUploadProgressBar = false;
    if (status) {
      this.flash('Image has been uploaded', 'success', {timeout: 5000});
      location.reload();
    }
  }

  fileDragIn() {
    console.log('');
  }

  fileDragOut() {
    console.log('');
  }

  showImageUploader() {
    if (this.checkUserWallet()) {
      this.step = 1;
    }

  }
  copyToClipboard() {
    const dummy = document.createElement('input');
    const text = window.location.href;

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    this.flash('Copied to clipboard', 'success', {timeout: 5000})
  }

  hideImageUploader() {
    this.step = 0;
    this.files = [];
  }

  createUrl(imageFile: File) {
    return window.URL.createObjectURL(imageFile);
  }

  async addFavoritePlace(place_id: any) {
    if (this.checkUserWallet()) {
      const add_favorite_place = await this.favorites_ser.addFavorite(this.profile!.id, 'location', place_id);
      if (add_favorite_place.status==200) {
        this.flash('Place has been added to your favorites', 'success', {timeout: 5000});
        this.checkPlaceIsFavorite()
      }
      if(add_favorite_place.status==409){
        this.flash('You have already added this place as a favorite', 'error', {timeout: 5000});
      }
    }
  }

  writeReview() {
    if (this.checkUserWallet()) {
      this.getRoutePath('new_review', {'place_id': this.place!.place_id, 'place_name': this.place!.name});
    }
  }

  checkUserWallet() {
    if (this.profile) {
      if (this.profile.wallet) {
        return true;
      } else {
        return false;
      }
    } else {
      this.openLogin();
    }
  }

  openLogin() {
    if (!(this.$refs['signup'] as any).isOpen()) {
      (this.$refs['login'] as any).showModal();
    }
  }

  openSignup() {
    (this.$refs['signup'] as any).showModal();
  }

  onLogin() {
    this.flash('You have logged in successfully', 'success', {timeout: 3000});
    location.reload();
  }

  onSignup() {
    this.flash('You have signed up successfuly', 'success', {timeout: 3000});
    location.reload();
  }
  async onSubmit(value: any,userId: any,reviewId: any ){
    const sendTip = await this.reviews_ser.sendTip(value,userId,reviewId);
    if (sendTip.status == 200) {
      this.flash('Your Tip has been sent successfully', 'success', {timeout: 5000});
      this.tipStatus=true;
    }else if(sendTip.status==555){
      this.showHandcashModal=true;
    }else{
      this.flash(sendTip.data.message, 'error', {timeout: 5000});
      this.tipStatus=false;
    }
  }
  async checkPlaceIsFavorite(){
    let favorite_places = await this.favorites_ser.places(this.profile!.id);
    if (favorite_places.status == 200) {
      favorite_places = favorite_places.data;
      const favorite_locations = new Array(favorite_places.length)
      if (favorite_places && favorite_places.length) {
        for (let i = 0; i < favorite_places.length; i++) {
          if(favorite_places[i].favorite==this.place_id){
            this.isFavorite=true;
          }
        }

      }
    }
  }

  openImageModal(photo_reference: any,index: number,source: string) {

    this.mainImageModel=photo_reference;
    this.mainImageIndex=index;
/*    this.$refs.PlaceImageModal.setImage(photo_reference);
    this.$refs.PlaceImageModal.setIndex(index);*/
    if(source=='google'){
      this.showGoogleImageModal = !this.showGoogleImageModal;
      this.showBritevueImageModal=false;
      this.showReviewImageModal=false;
    }else{
      this.showGoogleImageModal=false;
      this.showBritevueImageModal=!this.showBritevueImageModal;
      this.showReviewImageModal=false;
    }

  }
  openReviewImageModel(review_photos: any){
    this.reviewPhotos=review_photos;
    this.mainReviewImageModel=review_photos[0].url;
    this.showReviewImageModal=!this.showReviewImageModal;
    this.showGoogleImageModal=false;
    this.showBritevueImageModal=false;
}
  changeShowReviewImageModal(){
    this.showReviewImageModal=false;
  }
}
