































































































































import { Component, Inject, Vue } from "vue-property-decorator";
import RecentReviews from "@/components/RecentReviews/RecentReviews.vue";
import { UserService } from "@/services/UserService";
import { IUser } from "@/interfaces/IUser";
import {ReviewsService} from "@/services/ReviewsService";

@Component({
  name: "home",
  components: {
    RecentReviews,
  },
  metaInfo:{
    title: `Britevue - Blockchain verified reviews where reviewers get paid`,
    meta: [
      { name:'title',content:'Britevue - Blockchain verified reviews where reviewers get paid'},
      { name: 'description', content:  'Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!'},
      { itemprop: 'name', content: "Britevue"},
      { itemprop: 'description', content: "Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!"},
      { itemprop: 'image', content:  require('@/assets/images/logo_black.svg')},
      { property: 'og:type', content: "website"},
      { property: 'og:url', content: "https://britevue.com/"},
      { property: 'og:title', content: "Britevue - Blockchain verified reviews where reviewers get paid"},
      { property: 'og:description', content: "Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!"},
      { property: 'og:image', content:  require('@/assets/images/logo_black.svg')},
      { property: 'og:site_name', content: 'britevue'},
      { name: 'twitter:card', content: "summary_large_image"},
      { name: 'twitter:url', content: "https://britevue.com/"},
      { name: 'twitter:title', content: "Britevue - Blockchain verified reviews where reviewers get paid"},
      { name: 'twitter:description', content: "Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!"},
      { name: 'twitter:image', content: require('@/assets/images/logo_black.svg') },
    ]
  }
})

export default class Home extends Vue {
  @Inject("userService")
  user_ser!: UserService;

  @Inject('reviewsService')
  review_ser!: ReviewsService;

  flash: any;
  user: IUser | null = null;

  constructor() {
    super();
  }

  mounted() {
    if (this.$route.query.error != null) {
      switch(this.$route.query.error) {
        case "1":
          this.flash("Something went wrong connecting to Handcash. Please try again.", "error", {timeout: 10000})
          break
        case "2":
          this.flash("No user account was found created with Handcash. Please create an account or login with your username and password", "error", {timeout: 10000})
          break
        case "3":
          this.flash("Failed to signup via handcash. Please try again or create an account with Britevue", "error", {timeout: 10000})
          break
        case "4":
          this.flash("A britevue user already exists for this handcash wallet. Please login instead", "error", {timeout: 10000})
          break
        case "5":
          this.flash("A britevue user already exists with this handcash wallet. You are unable to link multiple accounts with the same wallet. Please contact support if you need assistance.", "error", {timeout: 10000})
          break
        default:
          this.flash("An unexpected error occurred")
      }
    }
    const user = this.user_ser.getCurrentUser();
    if (user) {
      this.user = user;
      //check user token is expired
      const checkUser = this.user_ser.getUser(this.user.id);
    }
  }
  signup() {
    document.getElementById("header-signup-button")!.click();
  }
  createSuccess(value: any){
    this.flash(value, "success", {timeout: 10000})
  }
  createError(value: any){
    this.flash(value, "error", {timeout: 10000})
  }

}
