

































































const HandcashAppID = process.env.VUE_APP_HC_APP_ID
import { Vue, Component, Inject} from 'vue-property-decorator';
import "./Signup.scss";
import {INewUser} from "@/interfaces/INewUser";
import {UserService} from "@/services/UserService";

@Component
export default class Signup extends Vue {
  user: INewUser

  @Inject('userService')
  user_ser!: UserService;
  loading = false;
  error_message: string;
  open: boolean;

  constructor() {
    super();
    this.open = false;
    this.error_message = "";
    this.user = {
      id: "",
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      active: true,
    };
  }
  isOpen() {
    return this.open
  }
  showModal(){
    this.open = true;
    this.$bvModal.show("signup-modal");
  }
  async signupHandcash() {
    const expireIn = new Date(new Date().getTime() + 5 * 60 * 1000);
    this.$cookies.set('signupUser', true, expireIn)
    window.location.href = 'https://app.handcash.io/#/authorizeApp?appId=' + HandcashAppID
  }

  async signup(){
    this.error_message = ""
    if (this.user.firstName == "" || this.user.lastName == "") {
      this.error_message = "Please supply your first and last name"
      return
    }
    if (this.user.id == "") {
      this.error_message = "Please pick a username"
      return
    }
    if (!/^([a-z0-9]{5,})$/.test(this.user.id)) {
      this.error_message= "Username must be at least 5 alphanumeric characters, all lowercase"
        return
    }
    if (this.user.email == "") {
      this.error_message = "Please supply your email address"
      return
    } else {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(this.user.email)) {
        this.error_message = "Please supply a valid email address"
        return
      }
    }
    if (this.user.password == "") {
      this.error_message = "Please supply a password"
    }
    if (this.user.password !== this.user.confirmPassword) {
      this.error_message = "Passwords do not match"
      return
    }
    if (this.user.password && this.user.password.length < 6) {
      this.error_message = "Password must be at least 6 characters long"
      return
    }
    this.loading = true;
    let err = null;
    await this.user_ser.signup(this.user).catch(e => err = e);
    if(!err){
      await this.user_ser.login(this.user.id, this.user.password!).catch(e => err = e);
      this.loading = false;
      if(!err){
        this.$bvModal.hide("signup-modal");
        this.open = false;
        this.$emit('onSignup', this.user.id);
      }
    } else {
      this.error_message = "username has already been taken, please select a new one"
    }
    this.loading = false;
  }
}
