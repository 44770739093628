


































































































































































































































































































































































































































import axios from "axios";
import { Vue, Component, Inject } from "vue-property-decorator";
import { IGooglePlace } from "@/interfaces/IGooglePlace";
import { Loader, LoaderOptions } from "google-maps";
import Header from "@/layouts/dashboard/Header/Header.vue";
import Footer from "@/layouts/dashboard/Footer/Footer.vue";
import { UserService } from "@/services/UserService";
import { IUser } from "@/interfaces/IUser";
import { PlaceService } from "@/services/PlaceService";
import { FavoritesService } from "@/services/FavoritesService";
@Component({
  components: {
    Header,
    Footer,
  },
  metaInfo:{
    title: "Britevue - Blockchain verified reviews where reviewers get paid",
    meta: [
      { name:'title',content:'Britevue - Blockchain verified reviews where reviewers get paid'},
      { name: 'description', content:  'Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!'},
      { itemprop: 'name', content: "Britevue"},
      { itemprop: 'description', content: "Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!"},
      { itemprop: 'image', content:  require('@/assets/images/logo_black.svg')},
      { property: 'og:type', content: "website"},
      { property: 'og:url', content: "https://britevue.com/"},
      { property: 'og:title', content: "Britevue - Blockchain verified reviews where reviewers get paid"},
      { property: 'og:description', content: "Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!"},
      { property: 'og:image', content:  require('@/assets/images/logo_black.svg')},
      { property: 'og:site_name', content: 'britevue'},
      { name: 'twitter:card', content: "summary_large_image"},
      { name: 'twitter:url', content: "https://britevue.com/"},
      { name: 'twitter:title', content: "Britevue - Blockchain verified reviews where reviewers get paid"},
      { name: 'twitter:description', content: "Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!"},
      { name: 'twitter:image', content: require('@/assets/images/logo_black.svg') },
    ]
  }
})
export default class Landing extends Vue {
  profile: IUser | null = null;
  lat: number;
  lng: number;
  type: string;
  loadingPlaces: boolean;
  loadingMap: boolean;
  radius: number;
  hideReviewAlert: boolean;
  reviewdPlaceId: string = "";
  reviewdPlaceName: string = "";
  input_location: any;

  @Inject("favoritesService")
  favorites_ser!: FavoritesService;

  @Inject("placeService")
  place_ser!: PlaceService;

  @Inject("userService")
  user_service!: UserService;

  flash: any;

  constructor() {
    super();
    this.lat = 45.422164978;
    this.lng = -75.6916639;
    this.type = "restaurant";
    this.loadingPlaces = true;
    this.loadingMap = true;
    this.radius = 5;
    this.hideReviewAlert = true;
    this.input_location = null;
    if (typeof this.$route.params.review != "undefined") {
      this.hideReviewAlert = false;
      this.reviewdPlaceId = this.$route.params.place_id;
      this.reviewdPlaceName = this.$route.params.place_name;
    }

    this.locatorButtonPressed();
  }

  async mounted() {
    await this.user_service.updateUserLocalStorage("");
    const user = await this.user_service.getCurrentUser();
    if (user) {
      this.profile = user;
      //check user token is expired
      const checkUser = this.user_service.getUser(this.profile.id);
    }

    await this.locatorButtonPressed();

    if(this.lat && this.lng && this.lat != 45.422164978 && this.lng != -75.6916639) {
      const address = await this.place_ser.getAddressForCoordinates(this.lat,this.lng);
/*       console.log('address');
       console.log(address);*/
      if (address.status != 200) {
        return
      }
      const input = document.getElementById("pac-input") as HTMLInputElement;

      input.value = address.data;
/*      const autocomplete = new google.maps.places.Autocomplete(input);

      console.log('autocomplete.getPlace()');
      console.log(autocomplete.getPlace());*/

    }
    await this.loadMap();
    await this.loadInitialPlaces();

  }
  async coordinates() {
    return `${this.lat}, ${this.lng}`;
  }
  async loadInitialPlaces() {
    this.loadingPlaces = true
    const google_places = await this.place_ser.searchGoogleLocations(this.lat,this.lng,this.type,this.radius);
    this.loadingPlaces = false;
   const places = await this.checkPlacesIsFavorite(google_places.data.Results);
    this.places = places;

    let initial_places=[];
    if (google_places.status== 200) {
      for (let i=0;i<google_places.data.Results.length;i++) {
        const brite_place = await this.place_ser.getBriteLocation(google_places.data.Results[i].place_id)

        initial_places.push(brite_place.data)
      }
      const places = await this.checkPlacesIsFavorite(initial_places);
      this.places=initial_places
      this.addLocationsToGoogleMaps();
    }

  }
  async findCloseByButtonPressed(e: any) {
    let el = document.getElementById("typeErrorMessage");
    if (el == null) {
      return "";
    }
    if (this.type == "") {
      el.innerHTML = "This field is required";
      return "";
    }
    el.innerHTML = "";
    el = document.getElementById("placeErrorMessage");
    if (el == null) {
      return "";
    }

    if (this.input_location == null) {
      el.innerHTML = "This field is required";
      return "";
    }
    el.innerHTML='';
    this.loadingPlaces = true
    const google_places = await this.place_ser.searchGoogleLocations(this.lat,this.lng,this.type,this.radius);
    this.places = []
    if (google_places.status==200) {
        for (let i=0;i<google_places.data.Results.length;i++) {
          const brite_place = await this.place_ser.getBriteLocation(google_places.data.Results[i].place_id)
          this.places.push(brite_place.data)
        }
        this.addLocationsToGoogleMaps();
    }
    this.loadingPlaces = false;
  }
  addLocationsToGoogleMaps() {
    this.loadMap();
  }
  async locatorButtonPressed() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
      },
      (error) => {
        console.log("Error getting location");
      }
    );


  }

  //dummy data
  places: IGooglePlace[] = [];

  async loadMap() {
    const options: LoaderOptions = {
      language: "en",
      libraries: [
        "drawing",
        "geometry",
        "localContext ",
        "places",
        "visualization",
      ],
    };
    const loader = new Loader(
      "AIzaSyDMqrI8hMqiyIPdQUkRUerdohx28Fuv4wE",
      options
    );
    const google = await loader.load();
    const con: any = this.$refs.map;
    const infowindow = new google.maps.InfoWindow();
    const infowindowContent = document.getElementById(
      "infowindow-content"
    ) as HTMLElement;
    const input = document.getElementById("pac-input") as HTMLInputElement;
    this.input_location=input.value;
    const autocomplete = new google.maps.places.Autocomplete(input);
    // Bind the map's bounds (viewport) property to the autocomplete object,
    // so that the autocomplete requests use the current map bounds for the
    // bounds option in the request.
    autocomplete.addListener("place_changed", () => {
      autocomplete.bindTo("bounds", map);
      // Set the data fields to return when the user selects a place.
      autocomplete.setFields([
        "address_components",
        "geometry",
        "icon",
        "name",
      ]);
      //get autocomplete location
      const input_location = autocomplete.getPlace();

      if (
        input_location == null ||
        input_location.geometry == null ||
        input_location.geometry.location == null
      ) {
        return;
      }
      this.input_location = input_location;
      this.lat = input_location.geometry.location.lat();
      this.lng = input_location.geometry.location.lng();

      infowindow.close();
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(
          input_location.geometry.location.lat(),
          input_location.geometry.location.lng()
        ),
        map: map,
      });
      marker.setVisible(false);
      const place = autocomplete.getPlace();

      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        this.flash(
          "No details available for input: '" + place.name + "'",
          "error",
          { timeout: 5000 }
        );
        return;
      }

      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17); // Why 17? Because it looks good.
      }
      marker.setPosition(place.geometry.location);
      marker.setVisible(true);

      let address = "";

      if (place.address_components) {
        address = [
          (place.address_components[0] &&
            place.address_components[0].short_name) ||
            "",
          (place.address_components[1] &&
            place.address_components[1].short_name) ||
            "",
          (place.address_components[2] &&
            place.address_components[2].short_name) ||
            "",
        ].join(" ");
      }
      infowindow.setContent(
        `<div class="ui header">${place.name}</div><p>${place.formatted_address}</p>`
      );
      infowindow.open(map, marker);
    });

    //load map
    const map = new google.maps.Map(con, {
      center: { lat: this.lat, lng: this.lng },
      zoom: 14,
    });

    this.places.forEach((place) => {
      const lat = place.geometry.location.lat;
      const lng = place.geometry.location.lng;
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(lat, lng),
        map: map,
      });
      google.maps.event.addListener(marker, "click", () => {
        infowindow.setContent(
          `<div class="ui header">${place.name}</div><p>${place.formatted_address}</p>`
        );
        infowindow.open(map, marker);
      });
    });
  }
  createReview(place_id: any,place_name: any){
    if(this.checkUserWallet()){
      this.$router.push({name: "new_review", params: { 'place_id': place_id,'place_name':place_name }});
    }
  }
  typeChanged() {
    const selectElement = document.querySelector(".type-label");
    if (selectElement != null) {
      selectElement.classList.add("d-none");
    }
  }
  radiusChanged() {
    const selectElement = document.querySelector(".radius-label");
    if (selectElement != null) {
      selectElement.classList.add("d-none");
    }
  }
  imageUrlAlt(event: any) {
    event.target.src = require("@/assets/images/no-photo-avatar.png");
  }
  removeReviewAlert() {
    this.hideReviewAlert = true;
  }
  getRoutePath(name: string, parameters: any) {
    return this.$router.push({ name: name, params: parameters });
  }
  goToPhotos(place: any) {
    this.getRoutePath("place_photos", { place: place });
  }
  async addFavoritePlace(place_id: any){
    if(this.checkUserWallet()){
      const add_favorite_place = await this.favorites_ser.addFavorite(this.profile!.id,'location',place_id);
      if(add_favorite_place.status==200){
        const element=document.getElementById('favorite_'+place_id) as HTMLElement ;
        element.classList.remove('far');
        element.classList.add('fas');
        this.flash('Place has been added as your favorite', 'success', {timeout: 5000});
      }
      if(add_favorite_place.status==409){
        this.flash('You have already added this place to favorite', 'error', {timeout: 5000});
      }
    }
  }
  getBaseUrl() {
    return window.location.origin + this.$route.path;
  }
  public async logout() {
    localStorage.removeItem("user");
  }
  checkUserWallet() {
    if(this.profile){
      if (this.profile.wallet) {
        return true;
      } else {
        return false;
      }
    }else{
      return false;
    }
  }

  async checkPlacesIsFavorite(places: any){
    let favorite_places = await this.favorites_ser.places(this.profile!.id);
    if (favorite_places.status == 200) {
      favorite_places = favorite_places.data;
      const favorite_locations = new Array(favorite_places.length)
      if (favorite_places && favorite_places.length) {
        for (let i = 0; i < favorite_places.length; i++) {
          for (let j = 0;j < places.length; j++) {
            if(favorite_places[i].favorite==places[j].place_id){
              places[j].isFavorite=true;
            }
          }

        }
      }
    }
  return places;
  }

}
