












































import {Component, Vue, Prop} from "vue-property-decorator";

@Component
export default class Tip extends Vue{
  @Prop({default: "USD"}) currency!: string;
  @Prop() reviewId!: string;
  @Prop() userId!: string;
  @Prop() tipStatus!: boolean;
  disabled: boolean;
  value: string;
  showPopover: boolean;
  tipSuccess: boolean;
  constructor() {
    super();
    this.disabled = true;
    this.value = "";
    this.showPopover = false;
    this.tipSuccess = false;

  }

  filter(evt: any){
    if(evt.keyCode === 69){
      evt.preventDefault();
    }
  }

  checkValue(){
    this.disabled = !this.value && !this.value.length || this.value === "0";
  }

 async submit(){
   const amount = parseFloat(this.value)
   const x =await  this.$emit('onSubmit',amount,this.userId,this.reviewId);
   console.clear();
   console.log(x);
   if (x.tipStatus) {
     this.tipSuccess = true;
   }


  }
  show(){
    this.$bvModal.show("tip_modal_" + this.reviewId);
    this.calculatePosition();
  }
  calculatePosition(){
    setTimeout(() => {
      const trigger: any = this.$refs['tip_modal_trigger_' + this.reviewId];
      const position = trigger.getBoundingClientRect();
      const modal = (this.$refs["tip_modal_" + this.reviewId] as any).$refs['content'];
      modal!.parentElement!.style.top = parseInt(position.top) - 200 + "px";
      modal!.parentElement!.style.right = parseInt(trigger.offsetLeft) - 180 + "px";
    }, 20);
  }

  togglePopover() {
    const overlay = document.getElementById("popover-overlay-" + this.reviewId);
    this.showPopover = !this.showPopover;
    if(this.showPopover == true) {
      if (overlay) overlay.classList.add("tip-popover-overlay--opened");
    } else {
      if (overlay) overlay.classList.remove("tip-popover-overlay--opened");
    }
  }

}
