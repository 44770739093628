





























































































import {Component, Inject, Vue} from "vue-property-decorator";
import Tip from "@/components/Tip/Tip.vue";
import {UserService} from "@/services/UserService";
import {IUser} from "@/interfaces/IUser";
@Component({
  components: {
    Tip,
  },
  metaInfo:{
    title: "Britevue - Blockchain verified reviews where reviewers get paid",
    meta: [
      { name:'title',content:'Britevue - Blockchain verified reviews where reviewers get paid'},
      { name: 'description', content:  'Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!'},
      { itemprop: 'name', content: "Britevue"},
      { itemprop: 'description', content: "Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!"},
      { itemprop: 'image', content:  require('@/assets/images/logo_black.svg')},
      { property: 'og:type', content: "website"},
      { property: 'og:url', content: "https://britevue.com/"},
      { property: 'og:title', content: "Britevue - Blockchain verified reviews where reviewers get paid"},
      { property: 'og:description', content: "Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!"},
      { property: 'og:image', content:  require('@/assets/images/logo_black.svg')},
      { property: 'og:site_name', content: 'britevue'},
      { name: 'twitter:card', content: "summary_large_image"},
      { name: 'twitter:url', content: "https://britevue.com/"},
      { name: 'twitter:title', content: "Britevue - Blockchain verified reviews where reviewers get paid"},
      { name: 'twitter:description', content: "Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!"},
      { name: 'twitter:image', content: require('@/assets/images/logo_black.svg') },
    ]
  }
})
export default class PlacePhotos extends Vue{

  place!: string;
  showImages: number;

  @Inject('userService')
  user_service!: UserService;

  profile: IUser | null = null;

  constructor() {
    super();
    if (typeof this.$route.params.place != 'undefined') {
      this.place = this.$route.params.place;
    } else {
      this.$router.push({name: "dashboard"});
    }

    this.showImages=4;
    this.user_service.updateUserLocalStorage("")
    const user = this.user_service.getCurrentUser();
    if (user) {
      this.profile = user;
      //check user token is expired
      const checkUser = this.user_service.getUser(this.profile.id);
    }
  }
  async mounted(){
    console.log('');
  }

  tip(amount: string){
    console.log('tipping:', amount);
  }
  /* eslint-disable @typescript-eslint/no-explicit-any*/
  imageUrlAlt(event: any) {
    event.target.src = require('@/assets/images/no-photo-avatar.png');
  }
  showMoreImages() {
    this.showImages += 4;
  }
  /* eslint-disable @typescript-eslint/no-explicit-any*/
  getRoutePath(name: string, parameters: any) {
    return this.$router.push({name: name, params: parameters});
  }
  writeReview(place_id: string,place_name: string) {
    if (this.checkUserWallet()) {
      this.getRoutePath('new_review',{'place_id':place_id,'place_name':place_name})
    }
  }
  checkUserWallet() {
    if (this.profile) {
      if (this.profile.wallet) {
        return true;
      } else {
        return false;
      }
    }else{
      return false;
    }
  }
}
