










































import { Component, Vue, Prop } from "vue-property-decorator";


type reviewImage = {
  url: string;
};
@Component
export default class Modal extends Vue {



  current_index: number;
  @Prop() showReviewImageModal!: boolean;
  @Prop() mainReviewImageModel!: string;
  @Prop({
    default: {
      url: ""
    },
  })
  photos!: [reviewImage];

  constructor() {
    super();
    this.current_index=0;
  }
  async mounted(){
    this.current_index=0;
  }
  setIndex(index: number) {
    this.current_index = index;
  }
  nextImage(){
    if(this.current_index < this.photos.length){
      this.current_index+=1;
      this.mainReviewImageModel=this.photos[this.current_index].url;
    }
  }
  previousImage(){
    if(this.current_index > 0){
      this.current_index-=1;
      this.mainReviewImageModel=this.photos[this.current_index].url;
    }
  }
  changeImage(index: number){
    this.current_index=index;
    this.mainReviewImageModel=this.photos[this.current_index].url;

  }
  closeModal(){
    this.$bvModal.hide('bv-review-example');
    this.showReviewImageModal = !this.showReviewImageModal;
    this.$emit('changeShowReviewImageModal', true);
  }
}
