import { render, staticRenderFns } from "./DashboardLayout.vue?vue&type=template&id=7cf729c4&scoped=true&"
import script from "./DashboardLayout.vue?vue&type=script&lang=ts&"
export * from "./DashboardLayout.vue?vue&type=script&lang=ts&"
import style0 from "./DashboardLayout.scss?vue&type=style&index=0&id=7cf729c4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cf729c4",
  null
  
)

export default component.exports