




















import {Component, Vue, Prop} from "vue-property-decorator";

type ModalAction = {
    text: string;
    url: string;
    variant: string;
    target: string;
}

@Component

export default class Modal extends Vue {


    @Prop() title!: string;
    @Prop() icon!: string;
    @Prop() body!: string;
    @Prop({default: false}) showHandcashModal!: boolean;
    @Prop({default: {text:'Close',url:'#',variant:'primary'}}) actions!: [ModalAction];
}
