























































































































import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "privacypolicy",
})
export default class PrivacyPolicy extends Vue {
}
