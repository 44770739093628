

































































































import {Vue, Component, Inject} from 'vue-property-decorator';
import {UserService} from "@/services/UserService";
import {IUser} from "@/interfaces/IUser";
import {NotificationsService} from "@/services/NotficationsService";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Login from "@/components/Login/Login.vue";
import Signup from "@/components/Signup/Signup.vue";

@Component({
  components: {
    VueGoogleAutocomplete,
    Login,
    Signup,
  }
})
export default class Header extends Vue{
  @Inject('userService')
  user_ser!: UserService;

  @Inject('notificationsService')
  notification_service!: NotificationsService;
  notifications: [] | null;
  user: IUser|null = null;
  showSearchBar: boolean;
  tooltip = true;
  place: any | null = null;
  openedMenu = false;
  constructor() {
    super();
    this.notifications=[];
    this.showSearchBar= true;
    /*if(this.$route.name=='landing'){
      this.showSearchBar= false;
    }*/
  }

  async mounted(){
    if (this.$route.query.token != null && this.$route.query.username != null) {
      const tokenObj = {
        username: this.$route.query.username,
        token: this.$route.query.token,
        expires_at: this.$route.query.expires_at
      }
      localStorage.setItem('token', JSON.stringify(tokenObj))
      await this.user_ser.updateUserLocalStorage(this.$route.query.username as string)
      const query = Object.assign({}, this.$route.query);
      delete query.token;
      delete query.expires_at;
      delete query.username;
      this.$router.replace({ query });
      window.location.reload()
    }
    const user = this.user_ser.getCurrentUser();
    if(user){
      this.user = user;
      // Weird hack for handcash since we don't have lastname all the time
      if (this.user.lastName == null) {
        this.user.lastName = ""
      }
      const x= await this.notification_service.getUserNotifications();
      if(x.status==200){
       this.notifications=x.data.notifications;
      }
    }
  }

  goHome() {
    this.$router.push({name: "home"});
  }
  getAddressData() {
    console.log('h')
  }
  getRoutePath(name: string,parameters: any){
    return this.$router.push({name: name, params: parameters});
  }
  async logout(){
    await this.user_ser.logout();
    if(this.$route.name=='home') {
      location.reload();
    } else {
      this.$router.push({name: "home"});
    }
  }
  findPlaceChanged(ret: any, place: any, id: any) {
    this.place = place;
    if (typeof place.place_id != "undefined") {
      this.getRoutePath("place", { id: place.place_id });
      if(this.$route.name=='place'){
        location.reload();
      }
    }
  }
  hideTooltip() {
    this.tooltip = false;
  }
  showTooltip() {
    this.tooltip = true;
  }
  openMenu(){
    this.openedMenu = !this.openedMenu;
  }


  openLogin() {
    (this.$refs["login"] as any).showModal();
  }

  openSignup() {
    (this.$refs["signup"] as any).showModal();
  }
  onLogin() {
    location.reload()
  }
  inputChange() {
    console.log("here");
  }
  onSignup(username: string) {
    this.getRoutePath("profile", {id: username})
  }
  writeReview() {
    if (this.user) {
      this.$router.push({ name: "dashboard" });
    } else {
      this.openLogin();
    }
  }
}
