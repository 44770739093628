








































































































































































import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "terms",
})
export default class Terms extends Vue {
}
