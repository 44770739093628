


























































import { Vue, Component, Inject } from "vue-property-decorator";
import Login from "@/components/Login/Login.vue";
import Signup from "@/components/Signup/Signup.vue";
import { UserService } from "@/services/UserService";
import VueGoogleAutocomplete from "vue-google-autocomplete";

import innerHeader from "@/layouts/dashboard/Header/Header.vue";

import { IUser } from "@/interfaces/IUser";
@Component({
  components: {
    innerHeader,

    VueGoogleAutocomplete,
  },
})
export default class Header extends Vue {
  @Inject("userService")
  user_ser!: UserService;
  user: IUser | null = null;
  place: any | null = null;
  tooltip = true;
  scrollPosition = 0;
  findPlaceChanged(ret: any, place: any, id: any) {
    this.place = place;
    if (typeof place.place_id != "undefined") {
      this.getRoutePath("place", { id: place.place_id });
    }
  }

  updateScroll() {
    this.scrollPosition = window.scrollY
  }
  
  mounted() {
    const user = this.user_ser.getCurrentUser();
    if (user) {
      this.user = user;
    }
    window.addEventListener('scroll', this.updateScroll);
  }

  destroy() {
    window.removeEventListener('scroll', this.updateScroll)
  }

  getRoutePath(name: string, parameters: any) {
    return this.$router.push({ name: name, params: parameters });
  }



  hideTooltip() {
    this.tooltip = false;
  }
  showTooltip() {
    this.tooltip = true;
  }
}
