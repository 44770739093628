import {Api} from "@/services/Api";
import {IReview} from "@/interfaces/IReview";
import {IUser} from "@/interfaces/IUser";
import {IRating} from "@/interfaces/IRating";
import Axios from "axios";
import router from "@/router";


export class VeritasService extends Api {
    public async createReview(receipt_id: string, receipt_secret: string, review_body: string, stars: any,imageHashes?: any, review_paywall?: number){
        const url = this.base_url + `/veritas/review`;
        let headers = {'Content-type': 'application/json; charset=UTF-8'} as any
        try {
          const token = JSON.parse(localStorage.token)
          if (token != null) {
            headers = {
              'Content-type': 'application/json; charset=UTF-8',
              "Authorization": "Bearer "+token.token
            }
          }
        } catch(e) {
          console.log('No existing user found, submitting review using email on receipt')
        }
        try {
            const res = await Axios.post(url,
                JSON.stringify({
                    "receiptID": receipt_id,
                    "receiptSecret": receipt_secret,
                    "reviewBody": review_body,
                    "photos": imageHashes,
                    "ratings": {
                        "quality": stars.quality.rate,
                        "experience": stars.experience.rate,
                        "value": stars.value.rate
                    },
                    "paywall": {
                        "cost": review_paywall
                    }
                }),
                {
                    headers: headers
                }
            );
            return res;
        } catch (e) {
            if (e.response.status === 401) {
                this.logout();
                router.push({name: 'home'});
            } else if (e.response.status === 403) {
                this.logout();
                router.push({name: 'home'});
            } else {
              return e.response
            }
        }
    }
    logout() {
        localStorage.removeItem('user');
    }

}
