





































































































import {Vue, Component, Inject, Prop} from 'vue-property-decorator';
import {IReview} from "@/interfaces/IReview.ts";
import {UserService} from "@/services/UserService";
import {ReviewsService} from "@/services/ReviewsService";
import {IUser} from "@/interfaces/IUser";
import Login from "@/components/Login/Login.vue";
import Signup from "@/components/Signup/Signup.vue";
import Tip from "@/components/Tip/Tip.vue";
import Modal from "@/components/Modal/Modal.vue";
@Component({
  components: {
    Tip,
    Modal
  }
})
export default class RecentReviews extends Vue{

  @Inject('userService')
  user_service!: UserService;

  @Inject('reviewsService')
  review_ser!: ReviewsService;
  showImages: number;
  activities:  IReview[] ;
  tipStatus: boolean;
  loadingReviews: boolean;
  showHandcashModal: boolean;

  @Prop() user!:  IUser | null;

  constructor() {
    super();
    this.showImages=6;
    this.loadingReviews=false;
    this.activities = [];
    this.tipStatus=false;
    this.showHandcashModal=false;
  }
  async mounted(){
    await  this.loadActivities();
  }
  async loadActivities(){
    this.loadingReviews=true

    const reviews = await this.review_ser.getRecentActivities();

    if (reviews.length > 0) {
      this.activities = reviews;
    }
    this.loadingReviews=false
  }


  imageUrlAlt(event: any) {
    event.target.src = require('@/assets/images/test/restaurant.png');
  }

  showMoreImages() {
    this.showImages +=6;
  }
  getRoutePath(name: string, parameters: any) {
    return this.$router.push({name: name, params: parameters});
  }

  async onSubmit(value: any,userId: any,reviewId: any ){
    const sendTip = await this.review_ser.sendTip(value,userId,reviewId);
    if (sendTip.status == 200) {
      this.$emit('success', 'Your Tip has been sent successfully');
      this.tipStatus=true;
    }else if(sendTip.status==555){
      this.showHandcashModal=true;
      this.tipStatus=false;
    }
    else{
      this.$emit('error', sendTip.data.message);
      this.tipStatus=false;
    }
  }

  async upvote(review_id: string, e: any) {
    e.preventDefault();

    if(this.checkUserWallet()){
      const spinner=document.getElementById('upvote-spinner-' + review_id) as HTMLElement;
      const upvoteElement=document.getElementById('upvote-' + review_id) as HTMLElement;
      const upvoteIcon=document.getElementById('upvote-icon-' + review_id) as HTMLElement;
      spinner.classList.remove('d-none');
      upvoteElement.classList.add('d-none');
      upvoteIcon.classList.add('d-none');
      const res = await this.review_ser.upvoteReview(this.user!.id, review_id);
      spinner.classList.add('d-none');
      upvoteElement.classList.remove('d-none');
      upvoteIcon.classList.remove('d-none');
      if (res.status == 200) {
        const upvoteResult = res.data
        if (typeof upvoteResult.txid != 'undefined') {
          const vote = upvoteElement!.textContent;
          upvoteElement!.innerHTML = (parseInt(vote!) + 1).toString();
          this.$emit('success', 'Successfully upvoted review');
        }
      } else if (res.status == 555) {
        this.showHandcashModal=true;
      } else {
        const msg = res.data.message
        if (typeof msg != 'undefined') {
          this.$emit('error', msg);
        }
      }
    }
  }
  async downvote(review_id: string, e: any) {
    e.preventDefault();
    if(this.checkUserWallet()){
      const spinner=document.getElementById('downvote-spinner-' + review_id) as HTMLElement;
      const downvoteElement=document.getElementById('downvote-' + review_id) as HTMLElement;
      const downvoteIcon=document.getElementById('downvote-icon-' + review_id) as HTMLElement;
      spinner.classList.remove('d-none');
      downvoteElement.classList.add('d-none');
      downvoteIcon.classList.add('d-none');
      const res = await this.review_ser.downReview(this.user!.id, review_id);
      spinner.classList.add('d-none');
      downvoteElement.classList.remove('d-none');
      downvoteIcon.classList.remove('d-none');
      if (res.status == 200) {
        const downvoteResult = res.data
        if (typeof downvoteResult.txid != 'undefined') {
          const vote = downvoteElement!.textContent;
          downvoteElement!.innerHTML = (parseInt(vote!) + 1).toString();
          this.$emit('success', 'Successfully downvoted review');
        }
      } else {
        const msg = res.data.message
        if (typeof msg != 'undefined') {
          this.$emit('error', msg);
        }
      }
    }
  }
  checkUserWallet() {
    if (this.user) {
      if (this.user.wallet) {
        return true;
      } else {
        return false;
      }
    }
  }

}
