import { render, staticRenderFns } from "./PlacePhotos.vue?vue&type=template&id=a3734838&scoped=true&"
import script from "./PlacePhotos.vue?vue&type=script&lang=ts&"
export * from "./PlacePhotos.vue?vue&type=script&lang=ts&"
import style0 from "./PlacePhotos.scss?vue&type=style&index=0&id=a3734838&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3734838",
  null
  
)

export default component.exports