







































































































































































































































































































































































































































































































































import {Vue, Component, Inject} from 'vue-property-decorator';
import {IReview} from "@/interfaces/IReview";
import {INotification} from "@/interfaces/INotification";
import {ISurvey} from "@/interfaces/ISurvey";
import {IUser} from "@/interfaces/IUser";
import {IPlace} from "@/interfaces/IPlace";
import {IFavorite} from "@/interfaces/IFavorite";
import {ReviewsService} from "@/services/ReviewsService";
import {FavoritesService} from "@/services/FavoritesService";
import {UserService} from "@/services/UserService";
import {PlaceService} from "@/services/PlaceService";
import {GoogleService} from "@/services/GoogleService";
import {SurveyService} from "@/services/SurveyService";
import Login from "@/components/Login/Login.vue";
import Signup from "@/components/Signup/Signup.vue";
import Modal from "@/components/Modal/Modal.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete"
import "./Handcash.css";
import {NotificationsService} from "@/services/NotficationsService";
import ReviewImageModal from "@/components/ImageModal/ReviewImageModal.vue";

const HandcashAppID = process.env.VUE_APP_HC_APP_ID
const MoneybuttonAppID = process.env.VUE_APP_MB_APP_ID
const MoneybuttonRedirectURI = process.env.VUE_APP_MB_REDIRECT_URI
@Component({
  components: {
    Login,
    Signup,
    Modal,
    VueGoogleAutocomplete,
    ReviewImageModal
  },
  metaInfo:{
    title: "Britevue - Blockchain verified reviews where reviewers get paid",
    meta: [
      { name:'title',content:'Britevue - Blockchain verified reviews where reviewers get paid'},
      { name: 'description', content:  'Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!'},
      { itemprop: 'name', content: "Britevue"},
      { itemprop: 'description', content: "Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!"},
      { itemprop: 'image', content:  require('@/assets/images/logo_black.svg')},
      { property: 'og:type', content: "website"},
      { property: 'og:url', content: "https://britevue.com/"},
      { property: 'og:title', content: "Britevue - Blockchain verified reviews where reviewers get paid"},
      { property: 'og:description', content: "Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!"},
      { property: 'og:image', content:  require('@/assets/images/logo_black.svg')},
      { property: 'og:site_name', content: 'britevue'},
      { name: 'twitter:card', content: "summary_large_image"},
      { name: 'twitter:url', content: "https://britevue.com/"},
      { name: 'twitter:title', content: "Britevue - Blockchain verified reviews where reviewers get paid"},
      { name: 'twitter:description', content: "Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!"},
      { name: 'twitter:image', content: require('@/assets/images/logo_black.svg') },
    ]
  }
})
export default class Profile extends Vue {
  @Inject('userService')
  user_service!: UserService;

  loadingReviews = false
  step: number;
  profile: IUser | null = null;
  profile_id!: string;
  currentUser: IUser | null = null;
  notifications: INotification[] = [];
  favorite_reviewers: IFavorite[] = [];
  favorite_places: IFavorite[] = [];
  favorite_user!: IUser;
  favorite_users: IUser[] = [];
  favorite_location!: IPlace;
  favorite_locations: IPlace[] = [];
  reviews: IReview[] = [];
  @Inject('reviewsService')
  reviews_ser!: ReviewsService;

  @Inject('placeService')
  place_ser!: PlaceService;

  @Inject('favoritesService')
  favorites_ser!: FavoritesService;

  @Inject('googleService')
  google_ser!: GoogleService;
  showReviews: number;

  @Inject('surveyService')
  survey_ser!: SurveyService;

  @Inject('notificationsService')
  notification_service!: NotificationsService;

  // Onboarding survey vars
  surveyLocalRestaurantQuestion: string;
  surveyLocalRestaurant: string;
  surveyMexicanRestaurantQuestion: string;
  surveyMexicanRestaurant: string;
  surveyPizzaPlaceQuestion: string;
  surveyPizzaPlace: string;
  surveyBreakfastPlaceQuestion: string;
  surveyBreakfastPlace: string;
  surveyCoffeeQuestion: string;
  surveyCoffee: string;

  onboardingSurvey!: ISurvey;
  flash: any;
  userPhotoLoading: boolean;
  submittingSurvey: boolean;
  reviewPhotos: [];
  showReviewImageModal: boolean;
  mainReviewImageModel: string;

  showHandcashModal: boolean;

  constructor() {
    super();
    if (typeof this.$route.params.id != 'undefined') {
      this.profile_id = this.$route.params.id;
    } else {
     // this.getRoutePath('home', '');
    }
    this.step = 2;
    this.showReviews = 6;
    // onboarding survey
    this.submittingSurvey = false;
    this.surveyLocalRestaurantQuestion = "What's your favorite restaurant?";
    this.surveyLocalRestaurant = "";
    this.surveyMexicanRestaurantQuestion = "What's your favorite Mexican restaurant?";
    this.surveyMexicanRestaurant = "";
    this.surveyPizzaPlaceQuestion = "What's your favorite pizza place?";
    this.surveyPizzaPlace = "";
    this.surveyBreakfastPlaceQuestion = "What's your favorite place to get breakfast?";
    this.surveyBreakfastPlace = "";
    this.surveyCoffeeQuestion = "What's your favorite coffee shop?";
    this.surveyCoffee = "";
    this.notifications = [];
    this.showHandcashModal = false;
    this.userPhotoLoading = false;
    this.reviewPhotos = [];
    this.showReviewImageModal = false;
    this.mainReviewImageModel = '';
    const user = this.user_service.getCurrentUser();
    if (user) {
      this.currentUser = user;
    }
  }

  async beforeMount() {
    if (this.$route.query.token != null && this.$route.query.username != null) {
      const tokenObj = {
        username: this.$route.query.username,
        token: this.$route.query.token,
        expires_at: this.$route.query.expires_at
      }
      localStorage.setItem('token', JSON.stringify(tokenObj))
      await this.user_service.updateUserLocalStorage(this.$route.query.username as string)
      const redirect = this.$cookies.get('loginPath')
      if (redirect != null && redirect != '') {
        this.$router.push({path: redirect})
        return
      }
    } else {
      await this.user_service.updateUserLocalStorage("");
    }
  }

  async mounted() {
    try {
      const fetchUser = await this.user_service.getUser(this.profile_id);
      if (fetchUser.status == 200) {
        await this.getUserNotification();
        this.profile = fetchUser.data;
        if (this.profile!.wallet != '') {
          this.step = 3;
        }
        if (this.profile!.onboardingSurveyDone == true) {
          this.step = 4;
        }

        this.allUserReviews();
        await this.loadFavoriteLocations();

        let favorite_reviewers = await this.favorites_ser.reviewers(this.profile!.id);
        if (favorite_reviewers.status == 200) {
          favorite_reviewers = favorite_reviewers.data;
          const favorite_users = new Array(favorite_reviewers.length)
          if (favorite_reviewers && favorite_reviewers.length) {
            this.favorite_reviewers = favorite_reviewers;
            for (let i = 0; i < favorite_reviewers.length; i++) {
              const favorite_user = await this.user_service.getUser(favorite_reviewers[i].favorite);
              if (favorite_user.status == 200) {
                this.favorite_user = favorite_user.data;
                favorite_users[i] = favorite_user.data;
              }
 
            }
            this.favorite_users = favorite_users;
          }

        }


      } else {
      //  this.getRoutePath('home', '');
      }
    } catch(e) {
      console.error(e)
      this.openLogin()
    }
  }

  surveyLocalRestaurantChanged(ret: any, place: any, id: any) {
    if (typeof place.place_id != "undefined") {
      this.surveyLocalRestaurant = place.place_id
    }
  }

  openLogin() {
    if (!(this.$refs['signup'] as any).isOpen()) {
      (this.$refs['login'] as any).showModal();
    }
  }

  onLogin() {
    this.flash('You have logged in successfully', 'success', {timeout: 3000});
    location.reload();
  }
  onSignup() {
    this.flash('You have signed up successfully', 'success', {timeout: 3000});
    location.reload();
  }
  openSignup() {
    (this.$refs['signup'] as any).showModal();
  }
  surveyPizzaPlaceChanged(ret: any, place: any, id: any) {
    if (typeof place.place_id != "undefined") {
      this.surveyPizzaPlace = place.place_id
    }
  }

  surveyCoffeeChanged(ret: any, place: any, id: any) {
    if (typeof place.place_id != "undefined") {
      this.surveyCoffee = place.place_id
    }
  }

  surveyBreakfastPlaceChanged(ret: any, place: any, id: any) {
    if (typeof place.place_id != "undefined") {
      this.surveyBreakfastPlace = place.place_id
    }
  }

  surveyMexicanRestaurantChanged(ret: any, place: any, id: any) {
    if (typeof place.place_id != "undefined") {
      this.surveyMexicanRestaurant = place.place_id
    }
  }

  imageUrlAlt(event: any) {
    event.target.src = require("@/assets/images/no-photo-avatar.png");
  }

  showModal() {
    (this.$refs['modal-edit-profile'] as any).show();
  }

  hideModal() {
    (this.$refs['modal-edit-profile'] as any).hide();
  }


  skipSurvey() {
    this.step = 4
  }
  async purchaseReview(review_id: string) {
    const spinner=document.getElementById('purchase-spinner-' + review_id) as HTMLElement;
    const button=document.getElementById('purchase-button-' + review_id) as HTMLElement;
    if(this.checkUserWallet()){
      spinner.classList.remove('d-none');
      button.classList.add('d-none')
      const res = await this.reviews_ser.buyReview(review_id)
      if (res.status == 200) {
        this.flash('Successfully purchased review in tx '+res.data.paymentTx, 'success', {timeout: 5000});
      } else if (res.status == 555) {
        this.showHandcashModal = true
      } else {
        this.flash('Failed to purchase review', 'error', {timeout: 5000})
      }
      spinner.classList.add('d-none')
      await this.allUserReviews()
    }
  }

  async loadFavoriteLocations() {
    if (this.profile) {
      let favorite_places = await this.favorites_ser.places(this.profile.id);

      const favorite_locations = new Array(favorite_places.length)
      if (favorite_places.status == 200) {
        favorite_places = favorite_places.data;
        if (favorite_places && favorite_places.length) {
          this.favorite_places = favorite_places;
          for (let i = 0; i < favorite_places.length; i++) {
            const favorite_location = await this.place_ser.getPlace(favorite_places[i].favorite);
            this.favorite_location = favorite_location;
            favorite_locations[i] = favorite_location;
          }

          this.favorite_locations = favorite_locations;
        }
      }
    }
  }

  async allUserReviews() {
    this.reviews = [];
    this.loadingReviews = true
    const reviews = await this.reviews_ser.allUserReviews(this.profile!.id);
    if (reviews.status == 200) {
      this.reviews = reviews.data;
      if (this.reviews != null) {
        await this.loadReviewLocations()
      }

    }
    this.loadingReviews = false
  }

  async loadReviewLocations() {
    const revs = []
    for (let i = 0; i < this.reviews.length; i++) {
      const rev = this.reviews[i];
      if (rev.placeID == null) {
        continue
      }
      try {
        const place = await this.place_ser.getPlace(rev.placeID)
        rev.title = place.name
        revs.push(rev)
      } catch (e) {
        console.error(e);
      }
    }
    this.reviews = revs
    this.loadingReviews = false
  }

  async submitOnboardingSurvey() {
    const surveyResponses = []
    let response = {
      question: "",
      answer: ""
    }
    if (this.surveyLocalRestaurant != "") {
      response = {
        question: this.surveyLocalRestaurantQuestion,
        answer: this.surveyLocalRestaurant
      }
      surveyResponses.push(response)
    }
    if (this.surveyMexicanRestaurant != "") {
      response = {
        question: this.surveyMexicanRestaurantQuestion,
        answer: this.surveyMexicanRestaurant
      }
      surveyResponses.push(response)
    }
    if (this.surveyBreakfastPlace != "") {
      response = {
        question: this.surveyBreakfastPlaceQuestion,
        answer: this.surveyBreakfastPlace
      }
      surveyResponses.push(response)
    }
    if (this.surveyCoffee != "") {
      response = {
        question: this.surveyCoffeeQuestion,
        answer: this.surveyCoffee
      }
      surveyResponses.push(response)
    }
    if (this.surveyPizzaPlace != "") {
      response = {
        question: this.surveyPizzaPlaceQuestion,
        answer: this.surveyPizzaPlace
      }
      surveyResponses.push(response)
    }
    if (this.profile == null) {
      return
    }
    if (surveyResponses.length == 0) {
      this.flash('Please include at least one survey response or skip the survey', 'error', {timeout: 10000})
      return
    }
    this.onboardingSurvey = {
      id: "1",
      username: this.profile.id,
      responses: surveyResponses
    }

    this.submittingSurvey = true

    const survey_res = await this.survey_ser.postSurvey(this.onboardingSurvey)
    if (survey_res.status == 200) {
      this.flash('Successfully submitted survey response! You were paid ' + survey_res.data.totalEarnings + ' cents in transaction ' + survey_res.data.paymentTx, 'success', {timeout: 10000});
      this.profile.earnings = survey_res.data.totalEarnings
      this.submittingSurvey = false
      this.loadFavoriteLocations()
      this.step = 4
    } else {
      this.flash('Something went wrong', 'error', {timeout: 10000});
      this.submittingSurvey = false
    }

  }

  redirectToHC() {
    if (this.profile != null) {
      this.$cookies.set('username', this.profile.id)
    }
    window.location.href = 'https://app.handcash.io/#/authorizeApp?appId=' + HandcashAppID
  }

  generateState() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 10; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  getRoutePath(name: string, parameters: any) {
    return this.$router.push({name: name, params: parameters});
  }

  async upvote(review_id: string, e: any) {
    e.preventDefault();
    if (this.checkUserWallet()) {
      const spinner=document.getElementById('upvote-spinner-' + review_id) as HTMLElement;
      const upvoteElement=document.getElementById('upvote-' + review_id) as HTMLElement;
      const upvoteIcon=document.getElementById('upvote-icon-' + review_id) as HTMLElement;
      spinner.classList.remove('d-none');
      upvoteElement.classList.add('d-none');
      upvoteIcon.classList.add('d-none');
      const res = await this.reviews_ser.upvoteReview(this.currentUser!.id, review_id);
      spinner.classList.add('d-none');
      upvoteElement.classList.remove('d-none');
      upvoteIcon.classList.remove('d-none');
      if (res.status == 200) {
        const upvoteResult = res.data
        const vote = document.getElementById('upvote-' + review_id)!.textContent;
        document.getElementById('upvote-' + review_id)!.innerHTML = (parseInt(vote!) + 1).toString();
        this.flash('Successfully upvoted review', 'success', {timeout: 5000})
      } else if(res.status == 555) {
        this.showHandcashModal = true
      } else {
        const msg = res.data.message
        if (typeof msg != 'undefined') {
          this.flash(msg, 'error', {timeout: 5000})
        }
      }
    }

  }

  async downvote(review_id: string, e: any) {
    e.preventDefault();
    if (this.checkUserWallet()) {
      const spinner=document.getElementById('downvote-spinner-' + review_id) as HTMLElement;
      const downvoteElement=document.getElementById('downvote-' + review_id) as HTMLElement;
      const downvoteIcon=document.getElementById('downvote-icon-' + review_id) as HTMLElement;
      spinner.classList.remove('d-none');
      downvoteElement.classList.add('d-none');
      downvoteIcon.classList.add('d-none');
      const res = await this.reviews_ser.downReview(this.profile!.id, review_id);
      spinner.classList.add('d-none');
      downvoteElement.classList.remove('d-none');
      downvoteIcon.classList.remove('d-none');
      if (res.status == 200) {
        const vote = document.getElementById('downvote-' + review_id)!.textContent;
        document.getElementById('downvote-' + review_id)!.innerHTML = (parseInt(vote!) - 1).toString();
        this.flash('Successfully downvoted review', 'success', {timeout: 5000})
      } else if(res.status == 555) {
        this.showHandcashModal = true
      } else {
        const msg = res.data.message
        if (typeof msg != 'undefined') {
          this.flash(msg, 'error', {timeout: 5000})
        }
      }
    }
  }

  async uploadProfilePicture(event: any) {
    // Reference to the DOM input element
    const input = event.target;
    if (input.files && input.files[0]) {
      const fileType = input.files[0]["type"];
      const validImageTypes = ["image/jpg", "image/gif", "image/jpeg", "image/png"];
      if (validImageTypes.includes(fileType)) {
        const photo = event.target.files[0] as File;
        const upload_user_photo = await this.user_service.uploadUserPhoto(photo, this.profile!.id);
        if (upload_user_photo) {
          this.userPhotoLoading = true;
          await this.user_service.updateUserLocalStorage(this.profile!.id);
          this.flash('Updated profile picture', 'success', {timeout: 5000});
          this.userPhotoLoading = false;
          location.reload();
        }
      } else {
        this.flash('Please try to upload a valid image', 'error', {timeout: 5000});
      }
    }
  }

  showMoreReviews() {
    this.showReviews += 6;
  }

  async tokenExpired() {
    await this.user_service.logout();
    this.$router.push({name: "home"});
  }

  async deleteReview(review_id: string) {
    if (this.checkUserWallet()) {
      if (confirm('Are you sure you want to delete this review')) {
        const deleteReview = await this.reviews_ser.deleteReview(review_id);
        if (deleteReview.status == 200) {
          this.allUserReviews();
        } else {
          this.flash('Failed to delete review', 'error', {timeout: 5000});
        }
      }
    }

  }

  async editReview(place_id: string, place_name: string, review_id: string) {
    if (this.checkUserWallet()) {
      this.getRoutePath('new_review', {'place_id': place_id, 'place_name': place_name, 'review_id': review_id});
    }
  }

  checkUserWallet() {
    if (this.currentUser) {
      if (this.currentUser.wallet) {
        return true;
      } else {
        return false;
      }
    } else {
      this.openLogin()
    }
  }

  async getUserNotification() {
    const x = await this.notification_service.getUserNotifications();
    if (x.status == 200) {
      this.notifications = x.data.notifications;
    }
  }

  async clearNotifications() {
    const x = await this.notification_service.clearUserNotifications();
    if (x.status == 200) {
      location.reload();
      /*await this.getUserNotification();*/
    }
  }

  async updateProfileForm(e: any) {

    e.preventDefault();
    let approved = true;
    const email = document.getElementById('email') as HTMLInputElement;
    //const password = document.getElementById('password') as HTMLInputElement;
    const first_name = document.getElementById('first_name') as HTMLInputElement;
    const last_name = document.getElementById('last_name') as HTMLInputElement;

    if (email.value.length > 0) {
      document.getElementById('email_error')!.classList.add('d-none');
    } else {
      document.getElementById('email_error')!.classList.remove('d-none');
      approved = false;
    }
    /*if (password.value.length > 0) {
      document.getElementById('password_error')!.classList.add('d-none');
    } else {
      document.getElementById('password_error')!.classList.remove('d-none');
      approved = false;
    }*/
    if (first_name.value.length > 0) {
      document.getElementById('first_name_error')!.classList.add('d-none');
    } else {
      document.getElementById('first_name_error')!.classList.remove('d-none');
      approved = false;
    }
    if (last_name.value.length > 0) {
      document.getElementById('last_name_error')!.classList.add('d-none');
    } else {
      document.getElementById('last_name_error')!.classList.remove('d-none');
      approved = false;
    }

    if (approved) {
      const update_profile = await this.user_service.updateProfile(this.profile!);
      if (update_profile.status == 200) {
        this.flash('Your profile has been updated successfully', 'success', {timeout: 3000});
      }
    }

  }

  openReviewImageModel(review_photos: any) {
    this.reviewPhotos = review_photos;
    this.mainReviewImageModel = review_photos[0].url;
    this.showReviewImageModal = !this.showReviewImageModal;

  }

  changeShowReviewImageModal() {
    this.showReviewImageModal = false;
  }
}
