import { render, staticRenderFns } from "./NewReview.vue?vue&type=template&id=55d08319&scoped=true&"
import script from "./NewReview.vue?vue&type=script&lang=ts&"
export * from "./NewReview.vue?vue&type=script&lang=ts&"
import style0 from "./NewReview.scss?vue&type=style&index=0&id=55d08319&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55d08319",
  null
  
)

export default component.exports