


































































































































































































































const HandcashAppID = process.env.VUE_APP_HC_APP_ID
import {Component, Inject, Vue} from "vue-property-decorator";
import Tip from "@/components/Tip/Tip.vue";
import Modal from "@/components/Modal/Modal.vue";
import {UserService} from "@/services/UserService";
import {VeritasService} from "@/services/VeritasService";
import {ReviewsService} from "@/services/ReviewsService";
import {PlaceService} from "@/services/PlaceService";
import {IUser} from "@/interfaces/IUser";
import {FavoritesService} from "@/services/FavoritesService";
import Login from "@/components/Login/Login.vue";
import Signup from "@/components/Signup/Signup.vue";

@Component({
  components: {
    Tip,
    Modal,
    Login,
    Signup,
  },
  metaInfo:{
    title: "Britevue - Blockchain verified reviews where reviewers get paid",
    meta: [
      { name:'title',content:'Britevue - Blockchain verified reviews where reviewers get paid'},
      { name: 'description', content:  'Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!'},
      { itemprop: 'name', content: "Britevue"},
      { itemprop: 'description', content: "Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!"},
      { itemprop: 'image', content:  require('@/assets/images/logo_black.svg')},
      { property: 'og:type', content: "website"},
      { property: 'og:url', content: "https://britevue.com/"},
      { property: 'og:title', content: "Britevue - Blockchain verified reviews where reviewers get paid"},
      { property: 'og:description', content: "Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!"},
      { property: 'og:image', content:  require('@/assets/images/logo_black.svg')},
      { property: 'og:site_name', content: 'britevue'},
      { name: 'twitter:card', content: "summary_large_image"},
      { name: 'twitter:url', content: "https://britevue.com/"},
      { name: 'twitter:title', content: "Britevue - Blockchain verified reviews where reviewers get paid"},
      { name: 'twitter:description', content: "Begin earning money for your reviews of businesses and places with Britevue. We will pay you for information about your hometown to get started!"},
      { name: 'twitter:image', content: require('@/assets/images/logo_black.svg') },
    ]
  }

})

export default class NewReview extends Vue {
  place_id: string;
  place_name: string;
  veritas_customer: string;
  veritas_receipt_id: string;
  veritas_receipt_secret: string;
  veritas_review: boolean;
  flash: any;
  submittingReview: boolean;
  review_body: string;
  review_paywall: number;
  @Inject('veritasService')
  veritas_ser!: VeritasService;
  @Inject('reviewsService')
  review_ser!: ReviewsService;
  @Inject('userService')
  user_ser!: UserService;

  @Inject('placeService')
  place_ser!: PlaceService;

  @Inject('favoritesService')
  favorites_ser!: FavoritesService;

  showHandcashModal: boolean;
  user: IUser | null = null;
  requestErrorMessage: string;
  latestTx: string;
  options: any;
  step: number;
  files: File[] = [];
  imageHashes: object[] = [];
  uploadProgress: number;
  showUploadProgressBar: boolean;
  dragImage: number;
  verified: boolean;
  reviewAnon: boolean;
  verifiedCustomerLogo: string;

  constructor() {
    super();
    if (typeof this.$route.params.place_id == 'undefined' && typeof this.$route.query.place_id == 'undefined') {
      this.$router.push({name: "dashboard"});
    }
    this.place_id = this.$route.params.place_id;
    this.place_name = this.$route.params.place_name;
    this.veritas_customer = '';
    this.veritas_receipt_id = '';
    this.veritas_receipt_secret = '';
    this.veritas_review = false;
    this.verified = false;
    this.reviewAnon = false;  
    // query params should take precedence
    if (
        typeof this.$route.query.place_id != 'undefined' &&
        typeof this.$route.query.place_name != 'undefined' &&
        typeof this.$route.query.receipt_id != 'undefined' &&
        typeof this.$route.query.receipt_secret != 'undefined') {
      this.veritas_review = true;
      this.place_id = this.$route.query.place_id as string;
      this.place_name = this.$route.query.place_name as string;
      this.veritas_customer = this.$route.query.customer as string;
      this.veritas_receipt_id = this.$route.query.receipt_id as string;
      this.veritas_receipt_secret = this.$route.query.receipt_secret as string;
      this.verified = true
    }

    this.review_body = '';
    this.review_paywall = 0.00
    this.submittingReview = false;
    this.showHandcashModal = false;
    this.requestErrorMessage = '';
    this.latestTx = '';
    this.verifiedCustomerLogo = "@/assets/images/barpay-logo-white.png" //TODO: default in null and get customer logo
    const user = this.user_ser.getCurrentUser();
    if (user) {
      this.user = user;
    }
    this.options = {
      text: {
        color: '#FFFFFF',
        shadowEnable: true,
        shadowColor: '#000000',
        fontSize: 14,
        fontFamily: 'Helvetica',
        dynamicPosition: false,
        hideText: false
      },
      progress: {
        color: '#cc7a00',
        backgroundColor: '#333333'
      },
      layout: {
        height: 35,
        width: 140,
        verticalTextAlign: 61,
        horizontalTextAlign: 43,
        zeroOffset: 0,
        strokeWidth: 30,
        progressPadding: 0,
        type: 'line'
      }
    }
    this.uploadProgress = 0;
    this.showUploadProgressBar = false;
    this.step = 1;
    this.dragImage = 0;
  }
  async loginHandcash() {
    const expireIn = new Date(new Date().getTime() + 5 * 60 * 1000);
    this.$cookies.set('loginUser', true, expireIn)
    this.$cookies.set('loginPath', this.$route.fullPath, expireIn)
    window.location.href = 'https://app.handcash.io/#/authorizeApp?appId=' + HandcashAppID
  }

  async mounted() {
    if (typeof this.$route.params.review_id != 'undefined') {
      //fetch review here
      let fetched_review = await this.review_ser.getReview(this.$route.params.review_id);

      if (fetched_review.status == 200) {
        fetched_review = fetched_review.data;
        this.review_body = fetched_review.reviewBody;
        if (fetched_review.paywall != null) {
          this.review_paywall = fetched_review.paywall.cost;
        }
        this.stars['quality'].rate = fetched_review.ratings.quality;
        this.stars['experience'].rate = fetched_review.ratings.experience;
        this.stars['value'].rate = fetched_review.ratings.value;
        this.calculateRating();
        this.latestTx = fetched_review.latestTx;
        this.verified = fetched_review.verifiedInfo !== null; 
      }
    }
    if (this.user == null) {
      if (!this.veritas_review) {
        throw new Error('cannot create review with no user');
      }
      this.showLoginPromptModal();
    }
  }

  showHelpModal() {
    (this.$refs['modal-help-text'] as any).show();
  }

  showLoginPromptModal() {
    (this.$refs['modal-login-prompt'] as any).show();
  }

  submitAnonReview() {
    (this.$refs['modal-login-prompt'] as any).hide(); 
    this.reviewAnon = true;
  }

  openSignup() {
    (this.$refs['modal-login-prompt'] as any).hide();
    (this.$refs["signup-modal"] as any).showModal();
  }

  openLogin() {
    (this.$refs['modal-login-prompt'] as any).hide();
    (this.$refs["login-modal"] as any).showModal();
  }
  
  loginComplete() {
    location.reload()
  }

  signupComplete() {
    location.reload()
  }

  async onSubmit(e: any) {
    e.preventDefault();
    this.requestErrorMessage = '';
    const el = document.getElementById('errorMessage')
    if (el == null) {
      throw new Error('failed to find errorMessage div')
    }
    if (this.review_body == "") {
      el.innerHTML = 'Review Body is required';
      return '';
    }

    if (this.stars['quality'].rate == null || this.stars['experience'].rate == null || this.stars['value'].rate == null) {
      el.innerHTML = 'Please provide ratings before submitting review';
      return '';
    }
    el.innerHTML = '';
    if (this.user == null) {
      if (!this.veritas_review) {
        throw new Error('cannot create review with no user');
      }
    }
    
    this.submittingReview = true
    await this.uploadImages();

    if (typeof this.$route.params.review_id != 'undefined') {
      if (this.user == null) {
        throw new Error('cannot create review with no user')
      }
      const review = await this.review_ser.editReview(this.$route.params.review_id, this.latestTx, this.user, this.place_id, this.review_body, this.stars, this.imageHashes, this.review_paywall);

      if (review.status == 200) {
        this.flash('Your review has been successfully updated!', 'success', {timeout: 10000});
        this.$router.push({
          'name': 'dashboard',
          params: {'review': 'true', 'place_name': this.place_name, 'place_id': this.place_id}
        });
      } else {
        this.flash('Your review failed to be edited, please try again and if you continue to have problems please contact our support team', 'error', {timeout: 10000});
      }
    } else {
      if (this.veritas_review) {
        const review = await this.veritas_ser.createReview(this.veritas_receipt_id, this.veritas_receipt_secret, this.review_body, this.stars, this.imageHashes, this.review_paywall);
        if (review.status == 200) {
          this.flash('Your review has been successfully submitted!', 'success', {timeout: 10000});
          this.$router.push({
            'name': 'dashboard',
            params: {'review': 'true', 'place_name': this.place_name, 'place_id': this.place_id}
          });
        } else if (review.status == 555) {
          this.showHandcashModal = true
        } else {
          this.flash('Your review failed to be submitted, please try again and if you continue to have problems please contact our support team', 'error', {timeout: 10000});
        }
      } else {
        if (this.user == null) {
          throw new Error('cannot create review with no user')
        }
        const review = await this.review_ser.createReview(this.user, this.place_id, this.review_body, this.stars, this.imageHashes, this.review_paywall);
        if (review.status == 200) {
          this.flash('Your review has been successfully submitted!', 'success', {timeout: 10000});
          this.$router.push({
            'name': 'dashboard',
            params: {'review': 'true', 'place_name': this.place_name, 'place_id': this.place_id}
          });
        } else if (review.status == 555) {
          this.showHandcashModal = true
        } else {
          this.flash('Your review failed to be submitted, please try again and if you continue to have problems please contact our support team', 'error', {timeout: 10000});
        }
      }
    }
    this.submittingReview = false
  }

  async uploadImages() {
    this.step = 1;
    this.showUploadProgressBar = true;
    let i = 0;
    let status = false;
    const progress = 100 / (this.files.length);
    for (i = 0; i < this.files.length; i++) {

      //upload images
      const uploadPlaceImage = await this.place_ser.uploadPlaceImage(this.files[i], this.place_id);
      if (uploadPlaceImage.status != 200) {
        this.flash(uploadPlaceImage.data.message, 'error', {timeout: 5000});
        continue;
      }
      if (uploadPlaceImage.status == 200) {
        status = true;
        this.imageHashes.push({'hash': uploadPlaceImage.data.hash, 'id': uploadPlaceImage.data.id});
        this.flash('Image has been uploaded', 'success', {timeout: 5000});
      }
      this.uploadProgress += parseInt(progress.toFixed(2));
    }
    this.showUploadProgressBar = false;

  }

  stars: any = {
    quality: {
      title: "Quality",
      stars: 5,
      active: null,
      rate: null,
    },
    experience: {
      title: "Experience",
      stars: 5,
      active: null,
      rate: null,
    },
    value: {
      title: "Value",
      stars: 5,
      active: null,
      rate: null,
    }
  };
  rating_description = [
    "Bad",
    "Okay",
    "Good",
    "Very good",
    "Excellent",
  ];
  rating_result = "0";

  setActive(type: string, stars: number) {
    this.stars[type].active = stars;
  }

  clearStars(type: string) {
    this.stars[type].active = null;
  }

  setRate(type: string, stars: number) {
    this.stars[type].rate = stars;
    this.calculateRating();
  }

  paywallChanged(e: any) {
    if (e.target.value === '') {
      this.review_paywall = 0
    }
  }

  calculateRating() {
    let total_rated = 0;
    for (const type in this.stars) {
      total_rated += this.stars[type].rate;
    }
    this.rating_result = (total_rated / 3).toFixed(1);
  }

  getRoutePath(name: string, parameters: any) {
    return this.$router.push({name: name, params: parameters});
  }

   handleFileDrop(e: any) {
     this.dragImage = 1;
    const droppedFiles = e.dataTransfer.files;
    if (!droppedFiles) return;
    // this tip, convert FileList to array
    ([...droppedFiles]).forEach((f: any) => {
      this.files.push(f);
    });
    this.step = 2;
  }

  handleFileInput(e: any) {

    if (this.dragImage == 0) {
      const input_files = e.target.files;
      if (!input_files) return;
      // this tip, convert FileList to array
      ([...input_files]).forEach((f: File) => {
        this.files.push(f);
      });
      this.step = 2;
    }

  }

  removeFile(fileKey: any) {
    this.files.splice(fileKey, 1);
    if (this.files.length == 0) {
      this.step = 1;
    }
  }

  fileDragIn() {
    console.log('');
  }

  fileDragOut() {
    console.log('');
  }

  showImageUploader() {
    this.step = 1;
  }

  hideImageUploader() {
    this.step = 1;
    this.files = [];
  }

  createUrl(imageFile: File) {
    return window.URL.createObjectURL(imageFile);
  }

  async addFavoritePlace(place_id: any) {
    if (this.checkUserWallet()) {
      const add_favorite_place = await this.favorites_ser.addFavorite(this.user!.id, 'location', place_id);
      if (add_favorite_place.status == 200) {
        this.flash('Place has been added to your favorites', 'success', {timeout: 5000});
      }
      if (add_favorite_place.status == 409) {
        this.flash('You have already added this place as a favorite', 'error', {timeout: 5000});
      }
    }
  }

  checkUserWallet() {
    if (this.user) {
      if (this.user.wallet) {
        return true;
      } else {
        return false;
      }
    }
  }
  changeDragValue(){
    this.dragImage=0;
  }
}
