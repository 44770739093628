



































































import { Component, Vue, Prop } from "vue-property-decorator";

type PlaceImage = {
  photo_reference: string;
  url: string;
};

@Component
export default class Modal extends Vue {
  @Prop() placeName!: string;
  @Prop({ default: false }) showImageModal!: boolean;
  @Prop() mainImageModel!: string;
  @Prop() mainImageIndex!: number;
  @Prop() source!: string;
  current_image: string;
  current_index: number;
  @Prop({
    default: {
      photo_reference: ""
    },
  })


  imagesList!: [PlaceImage];
  constructor() {
    super();
    this.current_image='';
    this.current_index=0;
    console.log(this.imagesList);

  }
  async mounted(){
    this.current_index=0;
    if(this.source=='google'){
      this.current_image=this.imagesList[0].photo_reference;
    }else{
      this.current_image=this.imagesList[0].url;
    }


  }

  setImage(image: string) {
    this.current_image = image;
  }
  setIndex(index: number) {
    this.current_index = index;
  }
  nextImage(){

    if(this.current_index < this.imagesList.length){
      this.current_index+=1;
      if(this.source=='google'){
        this.current_image=this.imagesList[this.current_index].photo_reference;
      }else{
        this.current_image=this.imagesList[this.current_index].url;
      }

    }
  }
  previousImage(){
    if(this.current_index > 0){
      this.current_index-=1;
      if(this.source=='google'){
        this.current_image=this.imagesList[this.current_index].photo_reference;
      }else{
        this.current_image=this.imagesList[this.current_index].url;
      }

    }
  }
  changeImage(index: number){
    this.current_index=index;
    if(this.source=='google'){
      this.current_image=this.imagesList[this.current_index].photo_reference;
    }else{
      this.current_image=this.imagesList[this.current_index].url;
    }
  }
  closeModal(){
    this.$bvModal.hide('bv-modal-example');
    this.showImageModal = !this.showImageModal;
  }
}
