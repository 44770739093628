





















































import {Vue, Component, Inject} from 'vue-property-decorator';
import "./Login.scss";

import {UserService} from "@/services/UserService";
const HandcashAppID = process.env.VUE_APP_HC_APP_ID

@Component
export default class Login extends Vue {
  username: string;
  password: string;
  @Inject('userService')
  user_ser!: UserService;
  error_message: string;
  open: boolean;
  constructor() {
    super();
    this.username = "";
    this.password = "";
    this.open = false;
    this.error_message = "";
  }
  showModal(){
    this.open = true;
    this.$bvModal.show("login-modal");
  }
  async loginHandcash() {
    const expireIn = new Date(new Date().getTime() + 5 * 60 * 1000);
    this.$cookies.set('loginUser', true, expireIn)
    this.$cookies.set('loginPath', this.$route.fullPath, expireIn)
    window.location.href = 'https://app.handcash.io/#/authorizeApp?appId=' + HandcashAppID
  }
  async login(){
    this.error_message = "";
    let err = null;
    const res = await this.user_ser.login(this.username, this.password).catch(e => err = e);
    if(err || res.status === 400){
      this.error_message = "Please check your credentials.";
      return;
    }
    this.user_ser.currentUser = res;
    this.$bvModal.hide("login-modal");
    this.open = false;
    this.$emit('onLogin', true);
  }
  isOpen() {
    return this.open
  }
  signup(){
    this.open = false;
    this.$bvModal.hide("login-modal");
    this.$emit('openSignup', true);
  }
}
